import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BasicService } from '../../common/services/basic.service';
import { API_SMART_VOUCHER } from '../../connection.data';

@Injectable()
export class SmartVoucherService extends BasicService {
    constructor(http: HttpClient){
        super(http);
        this.baseUrl = API_SMART_VOUCHER;
    }

    availabilityStats(providerId: number|string): Observable<any>{
        const url = `${this.baseUrl}/availability/stats/${providerId}`;
        //console.log(url);
        return this.http.get(url);
    }

    assignatedStats(providerId: number|string, voucherSmartTypeId: number|string): Observable<any>{
        const url = `${this.baseUrl}/assignated/stats/${providerId}?voucher_smart_type_id=${voucherSmartTypeId}`;
        //console.log(url);
        return this.http.get(url);
    }
    
    getNotAssignedOneToOne(providerId: number|string, amount: number|string): Observable<any>{
        const url = `${this.baseUrl}/not-assigned/one-to-one/${providerId}?amount=${amount}`;
        //console.log(url);
        return this.http.get(url);
    }

    getNotAssignedDuo(providerId: number|string, amount: number|string): Observable<any>{
        const url = `${this.baseUrl}/not-assigned/duo/${providerId}?amount=${amount}`;
        //console.log(url);
        return this.http.get(url);
    }

    getExternalBalance(smartVoucher: any): Observable<any>{
        let url: string = "";
        if(smartVoucher.id) url = `${this.baseUrl}/balance/${smartVoucher.id}/`;
        if(smartVoucher.code) url = `${this.baseUrl}/balance?code=${smartVoucher.code}`;
        //console.log(url);
        return this.http.get(url);
    }

    getMovements(smartVoucher: any): Observable<any>{
        const url = `${this.baseUrl}/movements/${smartVoucher.id}/`;
        //console.log(url);
        return this.http.get(url);
    }

    block(smartVoucher: any): Observable<any>{
        const url = `${this.baseUrl}/block/${smartVoucher.id}/`;
        //console.log(url);
        return this.http.put(url, {});
    }

    unblock(smartVoucher: any): Observable<any>{
        const url = `${this.baseUrl}/unblock/${smartVoucher.id}/`;
        //console.log(url);
        return this.http.put(url, {});
    }

    credit(smartVoucher: any, amount: number|string): Observable<any>{
        const url = `${this.baseUrl}/credit/${smartVoucher.id}/`;
        //console.log(url);
        return this.http.put(url, {amount});
    }

    charge(smartVoucher: any, amount: number|string): Observable<any>{
        const url = `${this.baseUrl}/charge/${smartVoucher.id}/`;
        //console.log(url);
        return this.http.put(url, {amount});
    }

    updateState(smartVoucher: any, state: any): Observable<any>{
        const url = `${this.baseUrl}/state/${smartVoucher.id}/`;
        //console.log(url);
        return this.http.put(url, {state});
    }

    activateMonedero(smartVoucher: any): Observable<any>{
        const url = `${this.baseUrl}/activate-monedero/${smartVoucher.id}/`;
        //console.log(url);
        return this.http.put(url, {});
    }
}